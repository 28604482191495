.link-button {
  background-color: transparent;
  color: green;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
  outline: none !important;
  box-shadow: none;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}
.dropzone {
  margin: auto;
  width: 30%;
  height: 40%;
  background-color: white;
  outline: 2px dashed black;
}
.google-user {
  margin-right: 0.5rem;
}

.ramadan-card-text {
  text-align: left;
}

.side-nav {
  padding-left: 0px;
  padding-right: 0px;
}

.overall-row {
  padding-left: 0px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}
.dropdown {
  text-align: left;
}

.double {
  transition: 2;
  transform: scale(2);
  -ms-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  -moz-transform: scale(2);
  transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  vertical-align: text-top;
}

.modal_quiz {
  flex-wrap: nowrap;
  font-weight: bold;
  font-size: larger;
}

.wrap-drop {
  /* display: flex; */
  align-self: flex-end;
}

.modal-width {
  min-width: 80%;
}
