button:focus,
:hover {
  outline: 0;
}
.card {
  border-radius: 0%;
}

.panel-heading {
  border: 0;
  border-radius: 0;
  background: transparent;
}
